import React, { useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Content, FilterModal } from "./style";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { BiChevronsDown, BiChevronsUp } from "react-icons/bi";
import { BsFillCaretRightFill } from "react-icons/bs";
import { productContext } from '../../contexts/productsContext';
import { BtnPrimry } from "../../styles/Buttons";

const Products = () => {
    const [show, setShow] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [initialPage, setInitialPage] = useState(10);
    const [selected, setSelected] = useState();

    const { produtos, CategoriaUnica } = useContext(productContext);  // Usando o contexto para pegar os produtos e categorias do DatoCMS
    const selectRef = useRef([]);

    const handleShow = () => setShow((prev) => !prev);
    const handleShowModal = () => setShowModal((prev) => !prev);

    const handleChange = (e) => {
        const index = e.target.value;
        setSelected(index);
    
        produtos.sort((a, b) => {
            const { nome, valor, views, _firstPublishedAt } = a;
            const { nome: nomeB, valor: valorB, views: viewsB, _firstPublishedAt: dataB } = b;
    
            switch (index) {
                case 'priceDesc':
                    return valor - valorB;  // Ordem crescente de preço
                case 'priceAsc':
                    return valorB - valor;  // Ordem decrescente de preço
                case 'A-Z':
                    return nome.localeCompare(nomeB);  // Ordem alfabética crescente
                case 'Z-A':
                    return nomeB.localeCompare(nome);  // Ordem alfabética decrescente
                case 'moreSelling':
                    return viewsB - views;  // Mais vendidos primeiro
                case 'newTime':
                    return new Date(dataB) - new Date(_firstPublishedAt);  // Mais novos primeiro
                case 'oldTime':
                    return new Date(_firstPublishedAt) - new Date(dataB);  // Mais antigos primeiro
                default:
                    return 0;
            }
        });
    };

    const handleShowMore = () => setInitialPage((prev) => prev + 10);

    return (
        <Container>
            <div className='nav_products_title'>
                <p><Link to='/'>Ínicio</Link> / Produtos</p>
                <h3>Produtos</h3>
            </div>

            <FilterModal>
                <div className={`filter_mobile__actived ${showModal && 'showModal'}`}>
                    <div className="close_modal" onClick={handleShowModal}>
                        <AiOutlineCloseSquare /> <span>Filtrar por:</span>
                    </div>
                    <div className={`modal_category ${!show && 'show'}`}>
                        <h3>Categorias</h3>
                        <ul>
                            {[...CategoriaUnica.keys()].map((item, index) => (
                                <li key={index}>
                                    <Link to={`/produtos/${item}`} id={item}>{item}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="div_border"></div>

                    <div className="icon_down">
                        {show 
                            ? <BiChevronsDown onClick={handleShow} title="Ver mais" /> 
                            : <BiChevronsUp onClick={handleShow} title="Ver menos" />}
                    </div>
                </div>
            </FilterModal>

            <Content>
                <div className="ordenar_produto">
                    <div className="select_ordem">
                        <label><p>Ordernar por:</p></label>
                        <select 
                            value={selected} 
                            defaultValue='moreSelling' 
                            ref={selectRef} 
                            onChange={handleChange}
                        >
                            <option value='priceDesc'>Preço: Menor ao Maior</option>
                            <option value='priceAsc'>Preço: Maior ao Menor</option>
                            <option value='A-Z'>A - Z</option>
                            <option value='Z-A'>Z - A</option>
                            <option value='newTime'>Mais novo ao mais antigo</option>
                            <option value='oldTime'>Mais antigo ao mais novo</option>
                            <option value='moreSelling'>Mais vendidos</option>
                        </select>
                    </div>

                    <div className="filter_mobile" onClick={handleShowModal}>
                        <p>filtrar por</p>
                        <BsFillCaretRightFill />
                    </div>
                </div>

                <div className="products_content">
                    <div className="Filter_category">
                        <div className={`category ${!show && 'show'}`}>
                            <h3>Categorias</h3>
                            <ul>
                                {[...CategoriaUnica.keys()].map((item, index) => (
                                    <li key={index}>
                                        <Link to={`/produtos/${item}`} id={item}>{item}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="div_border"></div>
                        <div className="icon_down">
                            {show 
                                ? <BiChevronsDown onClick={handleShow} title="Ver mais" /> 
                                : <BiChevronsUp onClick={handleShow} title="Ver menos" />}
                        </div>
                    </div>

                    <div className="products">
                        <div className='produtos_wrapper'>
                            {produtos.slice(0, initialPage).map((item, index) => {
                                const { nome, imagemPrincipal, valor, slug } = item;
                                const priceMore = valor * 0.4;
                                const priceMorePercent = valor + priceMore;
                                const StringPriceMorePercent = priceMorePercent.toFixed(2).replace('.', ',');
                                const stringPrice = valor.toFixed(2).replace('.', ',');

                                return (
                                    <div className='item_sigle' key={index} slug={slug}>
                                        <div className='item_img'>
                                            <Link to={`/produto/${slug}`}>
                                                <img 
                                                    src={imagemPrincipal?.url} 
                                                    title={nome} 
                                                    alt={nome} 
                                                />
                                            </Link>
                                        </div>
                                        <Link to={`/produto/${slug}`}>
                                            <div className='item_descricao'>
                                                <h2>{nome}</h2>
                                                <div className='priceNoDescont'>
                                                    <div className='barraDecont'></div>
                                                    <p>DE <strong>R${StringPriceMorePercent}</strong></p>
                                                </div>
                                                <p>Por apenas</p>
                                                <strong>R${stringPrice}</strong>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}

                            <div className='ver_produtos'>
                                <BtnPrimry primary pad upper onClick={handleShowMore}>
                                    Ver mais os produtos
                                </BtnPrimry>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Container>
    );
};

export default Products;
