import React,{useState, useContext} from "react";
import { Container, Content } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { BtnSecondary } from "../../styles/Buttons";
import { Context } from "../../contexts/authContext";

const Info = () =>{

    const [values,setValues] = useState([{
        nome: localStorage.getItem('user'),
        email: localStorage.getItem('email'),
        tel: localStorage.getItem('tel'),
    }]);
    

    const { editPerfil, msg } = useContext(Context);

    const navigate = useNavigate();

    const handleChangeValues = (value) =>{
        setValues((prev) => ({
            ...prev,
            [value.target.name]: value.target.value,
        }))
    }

    const handleEditPerfil = async (e) =>{
        e.preventDefault();        
            const isEdited = await editPerfil(values.nome, values.email, values.telefone);
            if(isEdited){
                navigate('/Perfil');
            }
    }

    return(
        <Container>
            <div className='nav_info_title'>
                <p><Link to='/'>Ínicio</Link> / <Link to='/perfil'>Minha Conta</Link> / Info</p>
                <h3>Minha Conta</h3>
            </div>

            <Content>
                <div className="form_editar">
                    <form>
                        <div className='input_style'>
                            <p>Nome Completo</p>
                            <input type='text' name='nome' onChange={handleChangeValues}/>                           
                        </div>
                        
                        <div className='input_style'>
                            <p>telefone</p>
                            <input type='text' name='telefone' onChange={handleChangeValues}/>                           
                        </div>
                        <div className='btn_editar'>
                            <BtnSecondary secondary upper pad onClick={handleEditPerfil}>Salvar alterações</BtnSecondary>
                        </div>
                        {<p className="msg_response">{msg}</p>}
                    </form>
                </div>
            </Content>
        </Container>
    );
}

export default Info;