import { datocms } from "../../hooks/datocms";

export const Allprodutos = async (first = 100, skip = 0) => {
    const query = `
        {
            allProdutos(first: ${first}, skip: ${skip}) {
                id
                nome
                categoria
                imagemPrincipal{
                    url(imgixParams: {fm: png, fit: crop, w: 1000, h: 750})
                }
                outrasImagens{
                    url(imgixParams: {fm: png, fit: crop, w: 1000, h: 750})
                }
                valor
                descricao
                tamanhoDoProduto{
                    tamanho
                }
                slug
                _status
                _firstPublishedAt
            }
        }
    `;

    try {
        const response = await datocms.post('', { query });
        
        return response.data.data.allProdutos;
    } catch (error) {
        console.error('Erro ao buscar conteúdo:', error.response ? error.response.data : error.message);
        return [];
    }
};



