import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Content } from './style';
import { Link } from 'react-router-dom';
import { BtnSecondary } from '../../styles/Buttons';
import { api } from '../../hooks/useApi';

const ResetPass = () => {
    
    const { token } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState({ password: '', confirmPassword: '' });

    const handleChangeValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmitReset = async (e) => {
        e.preventDefault();
        if (values.password !== values.confirmPassword) {
            alert('As senhas não coincidem!');
            return;
        }
        try {
            await api.post(`/mudar-senha/${token}`, {
                password: values.password,
                confirmPassword: values.confirmPassword
            });
            alert('Senha redefinida com sucesso!');
            navigate('/login');
        } catch (error) {
            console.log(error);
            alert('Erro ao redefinir senha. Tente novamente.');
        }
    };

    return (
        <Container>
            <div className='nav_recover__title'>
                <p><Link to='/'>Ínicio</Link> / <Link to='/login'>Login</Link> / Mudar minha senha</p>
                <h2>Mudar minha senha</h2>
            </div>
            <Content>
                <div className='form'>
                    <div className='msg_recover'>
                        <p>Escolha uma nova senha para a sua conta.</p>
                    </div>
                    <form onSubmit={handleSubmitReset}>
                        <p>Nova senha</p>
                        <input type='password' name='password' onChange={handleChangeValues} required />
                        <p>Repetir nova senha</p>
                        <input type='password' name='confirmPassword' onChange={handleChangeValues} required />
                        <div className='submit_form'>
                            <BtnSecondary secondary pad upper type="submit">Mudar senha</BtnSecondary>
                        </div>
                    </form>
                </div>
            </Content>
        </Container>
    );
};

export default ResetPass;
