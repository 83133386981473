import React, { useState } from 'react';
import { Container, Content } from './style';
import { Link } from 'react-router-dom';
import { BtnSecondary } from '../../styles/Buttons';
import { api } from '../../hooks/useApi';

const RecoverPass = () => {
    const [values, setValues] = useState({ email: '' });

    const handleChangeValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmitRecover = async (e) => {
        e.preventDefault();
        try {
            await api.post('/recuperar-senha', { email: values.email });
            alert('Verifique o e-mail informado para redefinir sua senha!');
        } catch (error) {
            console.log(error);
            alert('Erro ao enviar e-mail de recuperação de senha. Tente novamente.');
        }
    };

    return (
        <Container>
            <div className='nav_recover__title'>
                <p><Link to='/'>Ínicio</Link> / <Link to='/login'>Login</Link> / Recuperar minha senha</p>
                <h2>Recuperar minha senha</h2>
            </div>
            <Content>
                <div className='form'>
                    <div className='msg_recover'>
                        <p>Vamos te enviar um e-mail para poder alterar a sua senha.</p>
                    </div>
                    <form onSubmit={handleSubmitRecover}>
                        <p>Email</p>
                        <input type='email' name='email' onChange={handleChangeValues} required />
                        <div className='submit_form'>
                            <BtnSecondary secondary pad upper type="submit">Enviar email</BtnSecondary>
                        </div>
                    </form>
                </div>
            </Content>
        </Container>
    );
};

export default RecoverPass;
