import React, { useState, useRef, useEffect, useContext } from 'react';
import { Container, Content } from './style';
import { Link, useParams } from 'react-router-dom';
import { FaArrowUp, FaArrowDown, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { BtnPrimry } from '../../styles/Buttons';
import { productContext } from '../../contexts/productsContext'; // Contexto já configurado
import { Allprodutos } from '../../lib/datocms/produtos'; // Importando função correta

const SingleProduct = () => {
    const produtoRef = useRef(null);
    const [sigleItem, setSigleItem] = useState({});
    const [arrImgs, setArrImgs] = useState([]);
    const [tamanho, setTamanho] = useState('');
    const [tamanhosDisponiveis, setTamanhosDisponiveis] = useState([]);
    const carousel = useRef(null);
    const mobileCorousel = useRef(null);
    const imgIndex = useRef([]);
    imgIndex.current = [arrImgs];
    const mainImgProduct = useRef(null);
    const { insertProductOnCart } = useContext(productContext); // Função de adicionar ao carrinho
    const { slug } = useParams();

    useEffect(() => {
        const fetchProduct = async () => {
            const allProdutos = await Allprodutos(); // Obtendo produtos do DatoCMS
            const product = allProdutos.find(item => item.slug === slug); // Filtrando por slug
            setSigleItem(product);
            setArrImgs(product.outrasImagens);
            setTamanhosDisponiveis(product.tamanhoDoProduto.map(t => t.tamanho));
        };
        fetchProduct();
    }, [slug]);

    useEffect(() => {
        // Rola para a posição do elemento "produto" quando o componente é montado
        if (produtoRef.current) {
            produtoRef.current.scrollIntoView();
        }
    }, [sigleItem]);

    const handleClickUp = () => carousel.current.scrollTop -= carousel.current.offsetHeight;
    const handleClickDown = () => carousel.current.scrollTop += carousel.current.offsetHeight;
    const handleClickLeft = () => mobileCorousel.current.scrollLeft -= mobileCorousel.current.offsetWidth;
    const handleClickRight = () => mobileCorousel.current.scrollLeft += mobileCorousel.current.offsetWidth;

    const { nome, valor, categoria } = sigleItem;
    const stringPrice = valor?.toFixed(2).replace('.', ',');
    const parcela = (valor / 2)?.toFixed(2).replace('.', ',');

    return (
        <Container>
            <div className='nav_item_title'>
                <p ref={produtoRef}><Link to='/'>Ínicio</Link> / <Link to={`/produtos/${categoria}`}>{categoria}</Link> / {nome}</p>
                <h3>{nome}</h3>
            </div>

            <Content>
                <div className='produto'>
                    {/* Carousel Desktop */}
                    <div className='carousel_images_desktop'>
                        <div className='arrow_up'><FaArrowUp onClick={handleClickUp} /></div>
                        <div className='img_wrapper' ref={carousel}>
                            {arrImgs.map((img, index) => (
                                <div className='item_img' key={index}>
                                    <img
                                        alt='item_img'
                                        src={img.url}
                                        ref={(el) => imgIndex.current[index] = el}
                                        onClick={() => mainImgProduct.current.src = imgIndex.current[index].src}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='arrow_down'><FaArrowDown onClick={handleClickDown} /></div>
                    </div>

                    <div className='main_image'>
                        <img className='img_principal' alt='img_principal' src={arrImgs[0]?.url} ref={mainImgProduct} />
                        
                        {/* Carousel Mobile */}
                        <div className='carousel_images_mobile'>
                            <div className='arrow_left'><FaArrowLeft onClick={handleClickLeft} /></div>
                            <div className='img_wrapper' ref={mobileCorousel}>
                                {arrImgs.map((img, index) => (
                                    <div className='item_img' key={index}>
                                        <img
                                            alt='item_img'
                                            src={img.url}
                                            ref={(el) => imgIndex.current[index] = el}
                                            onClick={() => mainImgProduct.current.src = imgIndex.current[index].src}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='arrow_right'><FaArrowRight onClick={handleClickRight} /></div>
                        </div>

                        <div className='info_produto'>
                            <h2>R$ {stringPrice}</h2>
                            <p>2x de R${parcela} sem juros</p>

                            {/* Seleção de tamanho */}
                            <div className="size-selection">
                                <p>Escolha o tamanho:</p>
                                <div className="sizes">
                                    {tamanhosDisponiveis.map((size) => (
                                        <button
                                            key={size}
                                            onClick={() => setTamanho(size)}
                                            className={tamanho === size ? 'selected' : ''}
                                        >
                                            {size}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <BtnPrimry
                                primary
                                pad
                                upper
                                onClick={() => {
                                    if (!tamanho) {
                                        alert("Por favor, selecione um tamanho");
                                        return;
                                    }
                                    insertProductOnCart({ ...sigleItem, tamanho });
                                }}
                            >
                                incluir no carrinho +
                            </BtnPrimry>
                            {
                                sigleItem?.descricao ? 
                                <div className='descricao'>
                                    <h3>Descrição: </h3>
                                    <p>{sigleItem?.descricao}</p>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </Content>
        </Container>
    );
};

export default SingleProduct;
