import React, { useState, useRef } from 'react';
import { Container, Content } from './style';
import { Link } from 'react-router-dom';
import { BtnSecondary } from '../../styles/Buttons';
import { api } from '../../hooks/useApi';

const Contato = () => {
    const [values, setValues] = useState({
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
    });
    const [loading, setLoading] = useState(false);
    const formContato = useRef();

    const handleChangeValues = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmitContact = (event) => {
        event.preventDefault();
        setLoading(true);
        api.post('/contato', values)
            .then((response) => {
                setLoading(false);
                alert('Mensagem enviada com sucesso. Te responderemos o mais breve possível!');
                formContato.current.reset();
                setValues({ nome: '', email: '', telefone: '', mensagem: '' }); // Limpar os valores do estado
            })
            .catch((error) => {
                console.error(error);
                alert('Erro ao enviar a mensagem. Tente novamente.');
            });
    };

    return (
        <Container>
            <div className='nav_contato__title'>
                <p><Link to='/'>Ínicio</Link> / Contato</p>
                <h2>Contato</h2>
            </div>

            <Content>
                <div className='contato_wrapper'>
                    <div className='detalhes'>
                        <h3>DETALHES DE CONTATO</h3>
                        {/* <p>(88) 99978-2173</p> */}
                        <a href="mailto:suporte@njresportes.com">suporte@njresportes.com</a>
                    </div>
                    <div className='social'>
                        <h3>REDES SOCIAIS</h3>
                        <a href='https://www.instagram.com/njresportesof/' target='_blank' rel='noopener noreferrer'>Instagram</a>
                        <a href='https://www.facebook.com' target='_blank' rel='noopener noreferrer'>Facebook</a>
                    </div>
                    <div className='form_contato'>
                        <form ref={formContato} onSubmit={handleSubmitContact}>
                            <div className='input_style'>
                                <p>Nome Completo</p>
                                <input type='text' name='nome' required onChange={handleChangeValues} />
                            </div>
                            <div className='input_style'>
                                <p>Email</p>
                                <input type='email' name='email' required onChange={handleChangeValues} />
                            </div>
                            <div className='input_style'>
                                <p>Telefone (opcional)</p>
                                <input type='text' name='telefone' onChange={handleChangeValues} />
                            </div>
                            <div className='input_style'>
                                <p>Mensagem (opcional)</p>
                                <textarea name='mensagem' onChange={handleChangeValues}></textarea>
                            </div>
                            <div className='submit_form'>
                            <BtnSecondary 
                            secondary
                            upper 
                            pad 
                            disabled={loading} // Desativa o botão durante o loading
                            style={{ 
                                backgroundColor: loading ? '#68C9A9' : '' // Define a cor de fundo "secondary" durante o loading
                            }}                    
                        >
                            {loading ? (
                                <svg width="20" height="20" viewBox="0 0 300 150"><path fill="none" stroke="#FFFFFF" stroke-width="30" stroke-linecap="round" stroke-dasharray="300 385" stroke-dashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"><animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate></path></svg>
                            ) : "Enviar"}
                        </BtnSecondary>
                            </div>
                        </form>
                    </div>
                </div>
            </Content>
        </Container>
    );
};

export default Contato;
