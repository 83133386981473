import styled from 'styled-components';



export const Container = styled.div`
    width: 100%;
    background-color: #00bfa5 ;
`;


export const BtnMenuMobile = styled.div`
    display: none;
    

    @media screen and (max-width: 768px){
        display: block;

        svg{
            width: 30px;
            height: 30px;
            color: #fff;
            cursor: pointer;
        }
    }
`;

export const Content = styled.div`
    max-width: 1800px;
    padding: 10px 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form_buscar__mobile{display:none; cursor: pointer;}

    .menu_desktop{
        ul{
            list-style-type: none;
            display: flex;
            align-items: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
        }

        li{
            padding: 0 8px;
            svg{
                margin-right: 3px;
            }
            
        }

        a{
            color: #fff;
            text-decoration: none;
            :hover{
                cursor: pointer;
            }
        }

        a:hover{
            color: #64ffda;
        }
    }

    .form_buscar__desktop{
        width: 400px;
        position: relative;
        display: flex;
        align-items: center;

        form{
            width: 100%;
        }

        svg{
            position: absolute;
            left: 8px;
            color: #fff;
        }

        input{
            width: 100%;
            padding: 8px 0 8px 30px;
            border-radius: 6px;
            border: 0;
            background-color: #80cbc4 ;
            color: #fff;
        }

        input::placeholder{
            color: #fff;
        }
    }

    @media screen and (max-width: 768px){
        .menu_desktop{
            display: none;
        }

        .form_buscar__desktop{
            display: none;
        }

        .form_buscar__mobile{
            display: block;
            svg{
                color: #fff;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }

`;

export const SubHeaderContent = styled.div`
    .subHeader_desktop{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid rgba(44,62,80,0.05);
    }

    .subHeader_mobile{display: none;}

    .logo{
        width: 150px;
        height: 90px;
        margin: 0 10px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .header_links{
        display: flex;
        align-items: center;
        height: 100px;

        ul{
            list-style-type: none;
            display: flex;
        }

        li{
            margin: 0 10px
        }

        a{
            text-decoration: none;
            padding: 8px;
            color: rgb(80,80,80);
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 300;
        }

        a:hover{
            color: #ccc;
        }

        .cart_produtos{
            width: 120px;
            height: 100%;
            background-color: #2c3e50;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            :hover{
                background-color: #2c3e40;
                cursor: pointer;
            }

            svg{
                color: #fff;
                font-size: 30px;
            }

            

            .qtd_item{
                width: 25px;
                height: 25px;
                background-color: #fff;
                border: 1px solid #2c3e40;
                border-radius: 100%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 60%;
                left: 60%;
                transform: translate(-50%,-50%);

                p{
                    color: #2c3e40;
                }
            }

            .cart_preco{
                position: absolute;
                bottom: 3px;

                p{
                    color: #fff;
                    font-size: 14px;
                }
            }
        }
    }
    


    @media screen and (max-width: 768px){
        .subHeader_desktop{
            display: none;
        }

        .subHeader_mobile{
            display: block;
            background-color: #fff;

            .nav_header__mobile{
                border-bottom: 1px solid rgba(44,62,80,0.05);

                ul{
                    display: flex;
                    justify-content: space-around;
                    list-style-type: none;
                    align-items: center;
                }

                li{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }

                a{
                    text-decoration: none;
                    display: block;
                    padding: 12px;
                    color: #00bfa5;
                    text-transform: uppercase;
                    font-size: 15px;
                }
                button{
                    text-decoration: none;
                    display: block;
                    padding: 12px;
                    color: #00bfa5;
                    text-transform: uppercase;
                    font-size: 15px;
                    border: none;
                    background-color: transparent;
                }

                

               a:focus{
                   border-bottom: 2px solid #00bfa5;
                   outline: none;
               }

                a span{
                    padding: 2px 7px;
                    border-radius: 100%;
                    background-color: #2c3e40;
                    color: #fff;
                }          
                button span{
                    padding: 2px 7px;
                    border-radius: 100%;
                    background-color: #2c3e40;
                    color: #fff;
                }          
                
            }

            .header_logo__mobile{
                width: 100%;
                padding: 5px 2%;
                display: flex;
                justify-content: center;

                img{
                    width: 160px;
                    height: 100px;
                    object-fit: cover;
                }
            }
        }
    }

`;

export const NavMobile = styled.div`

    .overlay{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        position: absolute;
        z-index: 1;
        display: none;
    }

    .overlay.open{
        display: block;
        position: fixed;
        z-index: 3;
    }

    .modal{
        width: 250px;
        height: 100%;
        background-color: #222;
        display: flex;
        flex-direction: column;
        position: fixed;
        transition: all .45s;
        transform: translateX(-100%);
        z-index: 3;
    }

    .modal.open{
        transform: translateX(0);
    }

    .close_modal{
        
        position: absolute;
        right: 5px;
        top: 5px;
        svg{
            width: 20px;
            height: 20px;
            color: #fff;
            cursor: pointer;
        }
    }

    .modal_login{
        display: flex;
        width: 100%;
        padding: 20px 2%;
        background-color: #333;
        justify-content: space-around;
        align-items: center;
    }

    .icon_user{
        padding: 20px;
        background-color: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            color: #111;
        }
    }

    .user_links{
        display: flex;
        list-style-type: none;
        justify-content: center;
        a{
            text-decoration: none;
            color: #fff;
            margin: 0 10px;
            font-size: 12px;
            text-transform: uppercase;
        }
    }

    .info_links{
        text-align: left;
        ul{
            list-style-type: none;
            display: flex;
            flex-direction: column;

            li{
                display: inline-block;
            }

            a{
                padding: 20px;
                display: block;
                text-decoration: none;
                color: #fff;
                text-transform: uppercase;
                font-size: 12px;
                border-top: 1px solid #333;
            }
            
            a:hover{
                background-color: #333;
            }
        }
    }
`;



export const ModalSearch = styled.div`
    .search_overlay{
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,0.5);
        z-index: 3;
        display: none;
    }

    .search_overlay.search_open{
        display: block;
    }

    .search_mobile{
        width: 100%;
        position: absolute;
        background-color: #fff;
        z-index: 3;
        padding: 10px 0;
        transition: all .45s;
        transform: translateX(-100%);
        
        svg{
            font-size: 25px;
            cursor: pointer;
            position: relative;
            margin: 0 2%;
        }
        form{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            input{
            width: 100%;
            border: 0;
            padding: 10px;
            border: 0;
        }

        button{
            background-color: transparent;
            border: 0;
            padding: 0 2%;
        }

        }

    }

    .search_mobile.search_open{
        transform: translateX(0);
    }

`;


export const Cart = styled.div`
    
    height: 100%;
    .overlay{
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,0.5);
        z-index: 3;
        display: none;
    }

    .overlay.cartOpen{
        display: block;
    }

    .border{
        width: 100%;
        height: 1px;
        background-color: rgba(44, 62, 80, 0.1);
        margin: 10px auto;
    }
    

    .cart{
        width: 100%;
        max-width: 500px;
        height: 100%;
        top: 0;
        right: -100%;
        position: fixed;
        background-color: #fff;
        padding: 15px 10px;
        z-index: 3;
        transition: all ease-in-out 0.45s;
        overflow-y: auto;
        overflow-x: hidden;

        .cart_title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;
            h2{
                font-size: 20px;
            }

            svg{
                font-size: 25px;
                cursor: pointer;
                :hover{
                    color: red;
                }
            }

        }

        .item_info{
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3{
                font-size: 15px;
                font-weight: normal;
            }
        }


        .produto_single{
            position: relative;
            border-bottom: 1px groove #00bfa5;
            padding: 5px 0;
            margin: 10px 0;
        }

        .produto_content{
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .img_nome{
                display: flex;
                width: 100%;
                max-width: 400px;
               
                .img_produto{
                    width: 100%;
                    max-width: 300px;
                    height: 140px;
                    margin-right: 4px;
                    background-color: #ccc;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }


            .price_and_remove{
                display: flex;
                width: 100%;
                max-width: 100px;
                gap: 10px;


                svg{
                    cursor: pointer;

                    &:hover{
                        color: red;
                    }
                }
            }

        }

        .item_price{
            padding: 10px 5px 0 0;
        }

        .qtd_produtos{
            position: absolute;
            bottom: 10px;
            display: flex;
            align-items: center;
            left: 50%;
            transform: translateX(-50%);

            p{
                margin: 0 5px;
            }

            svg{
                font-size: 20px;
                cursor: pointer;
                color: #00bfa5;
            }
        }

    
        .cep_and_total_price{
                        
            .buscar_cep{
                color: #333;
                .title_cep{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    h3{
                        font-size: 17px;
                    }

                    svg{
                        margin-right: 5px;
                    }
                }

                .form_wrapper{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    input{
                        padding: 5px;
                        height: 37px;
                        margin-right: 5px;
                        border: 1px solid #ccc;

                        :focus{
                            border: 1px solid #00bfa5;
                        }
                    }

                    button{
                        width: 200px;
                    }

                }

                a{
                    margin-left: 50px;
                    color: #444;
                    font-size: 14px;
                    font-style: italic;
                }
            }

            .total_price{                
                margin: 10px 0;
                background-color: #f4f5f6;
                padding: 10px;
                color: #333;

                border-top: 4px solid #E9EBED;
                .title_total__price{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    h3{
                        font-size: 16px;
                        font-weight: normal;
                    }
                    p{
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .select_frete{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 60px;
                    }

                    .frete_prazo{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 30px;
                    }
                }
            }

            .parcela{
                text-align: right;
                font-weight: bold;
                font-size: 14px;
                color: #555;
            }

            .finalizar_compra{
                button{
                    width: 100%;
                    height: 45px;
                }
            }
        }


    }

    .cart.cartOpen{
        right: 0%;
        display: block;
    }

    @media screen and (max-width: 768px){

        .produto_single{

            .produto_content{
                gap: 20px;

                .img_nome{
                    width: 100%;
                    max-width: 300px;

                    .img_produto{
                        width: 100%;
                        max-width: 300px;
                        height: 100px;
                        margin-right: 4px;
                        background-color: #ccc;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                
            }
        }
    }

`;