import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Content, SubHeaderContent, BtnMenuMobile, NavMobile, ModalSearch, Cart } from './style';
import { BsFillCartFill, BsFillPersonFill, BsSearch } from 'react-icons/bs';
import { FiAlignLeft, FiArrowLeft } from 'react-icons/fi';
import { FaUserAlt, FaWindowClose, FaTrashAlt, FaTruckMoving } from 'react-icons/fa';
import { AiOutlineCloseSquare, AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import logo from '../../assets/J.png';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '../../contexts/authContext';
import { productContext } from '../../contexts/productsContext';
import { BtnPrimry } from '../../styles/Buttons';
import { api } from '../../hooks/useApi';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const { authenticated, user, signout } = useContext(Context);
    const { cart, insertProductOnCart, decrementProductOnCart, deleteProductOnCart } = useContext(productContext);
    const [cep, setCep] = useState('');
    const [isCalculating, setIsCalculating] = useState(false);
    const [error, setError] = useState(null);
    const [subtotal, setSubtotal] = useState(0);
    const [totalComFrete, setTotalComFrete] = useState(0);
    const [freteData, setFreteData] = useState(null);
    const [selectedFrete, setSelectedFrete] = useState('pac');
    const [forceUpdateSubtotal, setForceUpdateSubtotal] = useState(0); // Para re-renderizar subtotal
    const [forceUpdateItem, setForceUpdateItem] = useState(0); // Para re-renderizar formatedPriceMulti
    const [forceUpdateFrete, setForceUpdateFrete] = useState(0);


    const navigate = useNavigate();
    const links = useRef();

    // Limpar cache ao carregar a página
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            caches.keys().then(function (names) {
                for (let name of names) {
                    caches.delete(name);
                }
            });
        }
    }, []);

    useEffect(() => {
        links.current.focus();
    }, []);

    // Atualiza o subtotal quando o carrinho muda
    useEffect(() => {
        const sumPrice = cart.reduce((acc, item) => acc + item.valor * item.qtdCart, 0);
        setSubtotal(sumPrice);

        // Força a re-renderização
        setForceUpdateSubtotal((prev) => prev + 1);
        setForceUpdateItem((prev) => prev + 1);
        setForceUpdateFrete((prev) => prev + 1);
        // Atualiza o cache local do carrinho
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    // Atualizar o total com frete
    useEffect(() => {
        if (freteData && selectedFrete) {
            const fretePrice = selectedFrete === 'pac' ? freteData.pac.price : freteData.sedex.price;
            setTotalComFrete(subtotal + parseFloat(fretePrice));
        }
    }, [freteData, selectedFrete, subtotal]);

    // Função para calcular o frete
    const calcularFrete = useCallback(async () => {
    setIsCalculating(true);
    setError(null);

    try {
        const produtosFrete = cart.map(item => {
            const valorDeclarado = Math.min(item.valor * item.qtdCart, 4184.84);
            return {
                peso: item.peso || 1,
                comprimento: item.comprimento || 20,
                altura: item.altura || 10,
                largura: item.largura || 15,
                valorDeclarado,
                quantidade: item.qtdCart,
            };
        });

        const response = await api.post('/calcular-frete', {
            cepDestino: cep,
            produtos: produtosFrete,
        }, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        });

        let pacPrice = parseFloat(response.data.pac.price) || 0;
        if (cart.reduce((acc, item) => acc + item.qtdCart, 0) >= 3) {
            pacPrice = 0;  // Frete grátis para PAC a partir de 3 produtos
        }

        setFreteData({
            pac: { ...response.data.pac, price: pacPrice },
            sedex: { ...response.data.sedex, price: parseFloat(response.data.sedex.price) || 0 },
        });

        setSelectedFrete('pac');
        setTotalComFrete((subtotal + pacPrice).toFixed(2));
    } catch (error) {
        setError("Erro ao calcular o frete. Verifique o CEP e tente novamente.");
        console.error(error);
    } finally {
        setIsCalculating(false);
    }
}, [cart, cep, subtotal]);


    // Recalcular frete sempre que o CEP ou o carrinho mudar
    useEffect(() => {
        // Verificar se o CEP é válido antes de calcular o frete
        const cepValido = /^[0-9]{8}$/.test(cep.replace(/\D/g, '')); // Remove traços e valida o CEP
        if (cepValido && cart.length > 0) {
            calcularFrete(); // Recalcular o frete sempre que o CEP for válido
            setError(null);  // Remove erro caso o CEP seja válido
        } else {
            // Se o CEP for inválido ou removido, zere os valores do frete
            setFreteData({
                pac: { price: 0 },
                sedex: { price: 0 },
            });
            setTotalComFrete(subtotal); // Atualiza o total sem frete
            setError("Insira um CEP válido"); // Mensagem de erro caso o CEP seja inválido
        }
    
        setForceUpdateFrete((prev) => prev + 1);  // Força re-renderização do componente de frete
    }, [cep, cart]);  // Dependências: atualiza sempre que o CEP ou o carrinho mudar
    
    

    const handleFreteChange = (e) => {
        const freteValue = e.target.value;
        setSelectedFrete(freteValue);

        const fretePrice = freteValue === 'pac' ? freteData.pac.price : freteData.sedex.price;
        setTotalComFrete(subtotal + parseFloat(fretePrice));
    };

    const handleCepChange = (e) => {
        let value = e.target.value;
    
        // Remove qualquer caractere que não seja numérico
        const cepLimpo = value.replace(/\D/g, '');
    
        // Atualiza o estado do CEP
        setCep(cepLimpo);
    
        // Valida se o CEP tem 8 dígitos
        const cepValido = /^[0-9]{8}$/.test(cepLimpo);
    
        if (cepValido) {
            calcularFrete();  // Calcular frete se o CEP for válido
            setError(null);  // Remove erro de CEP inválido
        } else {
            // Se o CEP for inválido, define frete como 0
            setFreteData({
                pac: { price: 0 },
                sedex: { price: 0 },
            });
            setTotalComFrete(subtotal);  // Atualiza subtotal sem frete
            setError("Insira um CEP válido");
        }
    
        // Força a re-renderização do componente de frete
        setForceUpdateFrete((prev) => prev + 1);
    };
    
    
    

    const handlePayment = () => {
        if (!authenticated) {
            alert("Você precisa estar logado para finalizar a compra.");
            return;
        }

        if (!cep) {
            alert("Por favor, insira o CEP antes de finalizar a compra.");
            return;
        }

        // Limpar o CEP antes de validar
        const cepLimpo = cep.replace(/\D/g, '');

        if (!cepLimpo || !/^[0-9]{8}$/.test(cepLimpo)) {
            alert("Insira um CEP válido");
            return;
        }

        if (!freteData || (!freteData.pac && !freteData.sedex)) {
            alert("Por favor, calcule o frete antes de finalizar a compra.");
            return;
        }

        api.post(`/pagamento`, {
            cart,
            frete: freteData
        }).then(response => {
            window.location.href = response.data.url;
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleSearchOpen = () => setSearchOpen(prev => !prev);
    const handleCartOpen = () => setCartOpen(prev => !prev);
    const handleOpenNav = () => setIsOpen(prev => !prev);
    const handleLogout = () => {
        signout();
        navigate('/login');
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const searchTerm = e.target.buscar.value;
        setSearchOpen(false);
        navigate(`/buscar?termo=${encodeURIComponent(searchTerm)}`);
    };

    const initialQtd = cart.reduce((acc, item) => acc + item.qtdCart, 0);
    const formatedSubtotal = parseFloat(subtotal).toFixed(2).replace('.', ',');
    


    return (
        <Container>
            {/* Modal de busca e navegação */}
            <ModalSearch>
                <div className={`search_overlay ${searchOpen && 'search_open'}`} onClick={handleSearchOpen}></div>
                <div className={`search_mobile ${searchOpen && 'search_open'}`}>
                    <form onSubmit={onSubmit}>
                        <FiArrowLeft onClick={handleSearchOpen} />
                        <input type='text' name='buscar' placeholder='Buscar...' />
                        <button><BsSearch /></button>
                    </form>
                </div>
            </ModalSearch>

            <NavMobile>
                <div className={`overlay ${isOpen && "open"}`} onClick={handleOpenNav}></div>
                <div className={`modal ${isOpen && "open"}`}>
                    <div className={`close_modal`} onClick={handleOpenNav}><FaWindowClose /></div>
                    <div className="modal_login">
                        <div className="icon_user"><FaUserAlt /></div>
                        <div>
                            {!authenticated ?
                                <ul className="user_links">
                                    <li><Link to='/criar-conta' onClick={handleOpenNav}>Cadastre-se</Link></li>
                                    <li><Link to='/login' onClick={handleOpenNav}>Login</Link></li>
                                </ul>
                                :
                                <ul className={`user_links`} onClick={handleOpenNav}>
                                    <li><Link to='/Perfil'>{(user.nome).split(' ')[0]}</Link></li>
                                    <span style={{ color: '#fff' }}>|</span>
                                    <li><Link to='/login' onClick={handleLogout}>sair</Link></li>
                                </ul>
                            }
                        </div>
                    </div>

                    <div className="info_links">
                        <ul>
                            <li><Link to='/contato' onClick={handleOpenNav}>contato</Link></li>
                            <li><Link to="/politic" onClick={handleOpenNav}>política da loja</Link></li>
                        </ul>
                    </div>
                </div>
            </NavMobile>

            {/* Carrinho */}
            <Cart>
                <div className={`overlay ${cartOpen && 'cartOpen'}`} onClick={handleCartOpen}></div>
                <div className={`cart ${cartOpen && 'cartOpen'}`}>
                    <div className='cart_title'>
                        <h2>CARRINHO DE COMPRAS</h2>
                        <AiOutlineCloseSquare onClick={handleCartOpen} />
                    </div>

                    <div className='item_info'>
                        <h3>PRODUTO</h3>
                        <h3>SUBTOTAL</h3>
                    </div>

                    <div className='produtos_wrapper'>
                        {cart?.map((item, index) => {
                            const { nome, valor, outrasImagens } = item;
                            const priceMulti = valor * item.qtdCart;
                            const formatedPrice = valor?.toFixed(2).replace('.', ',');
                            const formatedPriceMulti = priceMulti?.toFixed(2).replace('.', ',');
                            
                            

                            return (
                                <div key={item.id} className='produto_single'>
                                    <div className='produto_content'>
                                        <div className='img_nome'>
                                            <div className='img_produto'>
                                                <img alt={`${outrasImagens[0]?.filename}`} src={outrasImagens[0]?.url} />
                                            </div>
                                            <p>{nome}</p>
                                        </div>
                                        <div className='price_and_remove'>
                                            <p key={forceUpdateItem}>R${(valor * item.qtdCart).toFixed(2).replace('.', ',')}</p>  {/* Cálculo atualizado diretamente */}
                                            <FaTrashAlt onClick={() => deleteProductOnCart(item)} />
                                        </div>
                                    </div>

                                    <div className='item_price'>
                                        <p>R${valor.toFixed(2).replace('.', ',')}</p>
                                    </div>

                                    <div className='qtd_produtos'>
                                        <AiFillMinusCircle onClick={() => decrementProductOnCart(item)} />
                                        <p>{item.qtdCart}</p>
                                        <AiFillPlusCircle onClick={() => insertProductOnCart(item)} />
                                    </div>
                                </div>

                            );
                        })}

                        {!subtotal ? '' : (
                            <div className='cep_and_total_price'>
                                <div className='buscar_cep'>
                                    <div className='form_wrapper'>
                                        <input
                                            type='text'
                                            name='cep'
                                            value={cep.replace(/(\d{5})(\d{3})/, '$1-$2')}
                                            onChange={handleCepChange}
                                            placeholder='Seu CEP'
                                        />
                                        <BtnPrimry upper primary onClick={calcularFrete}>calcular</BtnPrimry>
                                    </div>
                                    {!cep && <span style={{ color: 'red' }}>Por favor, insira seu CEP</span>}
                                </div>

                                <div className='total_price' key={forceUpdateSubtotal}>
                                    <div className='title_total__price'>
                                        <h3>Subtotal (sem frete)</h3>
                                        <p>R${parseFloat(subtotal || 0).toFixed(2).replace('.', ',')}</p>
                                    </div>

                                    {
                                        cep && (
                                            <div className='frete_prazo' key={forceUpdateFrete}>
                                        {freteData?.pac && (
                                            <div>
                                                <h3>Frete PAC</h3>
                                                <p>R${Number(freteData.pac.price || 0).toFixed(2).replace('.', ',')}</p>
                                                <p>Prazo de entrega: {freteData.pac.delivery_range?.min || 'N/A'} - {freteData.pac.delivery_range?.max || 'N/A'} dias úteis</p>
                                            </div>
                                        )}
                                        {freteData?.sedex && (
                                            <div>
                                                <h3>Frete SEDEX</h3>
                                                <p>R${Number(freteData.sedex.price || 0).toFixed(2).replace('.', ',')}</p>
                                                <p>Prazo de entrega: {freteData.sedex.delivery_range?.min || 'N/A'} - {freteData.sedex.delivery_range?.max || 'N/A'} dias úteis</p>
                                            </div>
                                        )}
                                    </div>
                                        )
                                    }

                                </div>

                                <div className='finalizar_compra'>
                                    <BtnPrimry upper primary onClick={handlePayment} disabled={!freteData || !cep || !authenticated}>
                                        finalizar compra
                                    </BtnPrimry>
                                    {!authenticated && (
                                        <p style={{ color: 'red' }}>Você precisa estar logado para finalizar a compra.</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Cart>

            {/* Resto do header */}
            <Content>
                {/* Menu Desktop */}
                <div className="menu_desktop">
                    <nav>
                        {!authenticated ?
                            <ul>
                                <li><BsFillPersonFill /><Link to='/criar-conta'>Cadastre-se</Link></li>
                                |
                                <li><Link to='/login'>Login</Link></li>
                            </ul>
                            :
                            <ul>
                                <li onClick={handleLogout}><Link to="/login">Sair</Link></li>
                                |
                                <li><Link to='/Perfil'><BsFillPersonFill /> Olá, {(user.nome).split(' ')[0]}</Link></li>
                            </ul>}
                    </nav>
                </div>
                <BtnMenuMobile>
                    <FiAlignLeft onClick={handleOpenNav} />
                </BtnMenuMobile>

                <div className="form_buscar__desktop">
                    <BsSearch />
                    <form onSubmit={onSubmit}>
                        <input type="text" name="buscar" placeholder="Buscar" />
                    </form>
                </div>

                <div className="form_buscar__mobile">
                    <BsSearch onClick={handleSearchOpen} />
                </div>
            </Content>

            <SubHeaderContent>
                <div className='subHeader_desktop'>
                    <div className="logo">
                        <Link to='/'><img src={logo} alt='logo' /></Link>
                    </div>
                    <div className='header_links'>
                        <ul>
                            <li><Link to='/'>início</Link></li>
                            <li><Link to='/produtos'>produtos</Link></li>
                            <li><Link to='/contato'>contato</Link></li>
                            <li><Link to='/politic'>política da loja</Link></li>
                        </ul>

                        <div className='cart_produtos' onClick={handleCartOpen}>
                            <div className='cart_content'>
                                <BsFillCartFill />
                                <div className='qtd_item'>
                                    <p>{initialQtd}</p>
                                </div>
                            </div>
                            <div className='cart_preco'>
                                <p>R${formatedSubtotal}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='subHeader_mobile'>
                    <div className='nav_header__mobile'>
                        <nav>
                            <ul className='nav_links_mobile'>
                                <li><Link ref={links} to='/'>início</Link></li>
                                <li><Link to='/produtos'>produtos</Link></li>
                                <li><button onClick={handleCartOpen}>carrinho <span>{initialQtd}</span></button></li>
                            </ul>
                        </nav>
                    </div>
                    <div className='header_logo__mobile'>
                        <Link to='/'><img alt='img_header' src={logo} /></Link>
                    </div>
                </div>
            </SubHeaderContent>
        </Container>
    );
};

export default Header;
