import React, { useContext, useState } from 'react';
import { Container, Content } from './style';
import { Link, useNavigate } from 'react-router-dom';
import { BtnSecondary } from '../../styles/Buttons';
import { Context } from '../../contexts/authContext';
import { useApi } from '../../hooks/useApi';

const Login = () => {
    const api = useApi();
    const [showPass, setShowPass] = useState(false);
    const [values, setValues] = useState({ email: '', password: '' });
    const [localMsg, setLocalMsg] = useState('');
    const { signin } = useContext(Context);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleShowPass = () => {
        setShowPass(prev => !prev);
    };

    const handleChangeValues = (value) => {
        setValues((prev) => ({
            ...prev,
            [value.target.name]: value.target.value,
        }));
    };

    const handleClickButton = async (e) => {
        e.preventDefault();
        setLocalMsg('');
        setLoading(true); // Ativa o estado de carregamento

        if (values.email && values.password) {
            const result = await api.signin(values.email, values.password);

            if (result.error) {
                setLocalMsg(result.error);
                setLoading(false); // Desativa o carregamento em caso de erro
            } else {
                const isLogged = await signin(values.email, values.password);
                if (isLogged) {
                    navigate('/');
                }
                setLoading(false); // Desativa o carregamento após o login
            }
        } else {
            setLocalMsg('Por favor, preencha todos os campos.');
            setLoading(false); // Desativa o carregamento caso faltem dados
        }
    };

    return (
        <Container>
            <div className='nav_login__title'>
                <p><Link to='/'>Ínicio</Link> / Login</p>
                <h2>Login</h2>
            </div>

            <Content>
                <div className='form_login'>
                    <form>
                        <div className='input_style'>
                            <p>Email</p>
                            <input type='email' name='email' onChange={handleChangeValues} required />
                        </div>
                        <div className='input_style'>
                            <p>Senha</p>
                            <button type="button" className='ver_senha' onClick={handleShowPass}>
                                {!showPass ? 'Mostrar' : 'Esconder'}
                            </button>
                            <input
                                type={showPass ? 'text' : 'password'}
                                name='password'
                                onChange={handleChangeValues}
                                autoComplete='off'
                                required
                            />
                        </div>
                        <div className='recuperar_conta'>
                            <Link to='/recuperar-conta'>Esqueceu a senha?</Link>
                        </div>
                        <div className='btn_entrar'>
                        <BtnSecondary 
                        secondary
                        upper 
                        pad 
                        onClick={handleClickButton} 
                        disabled={loading} // Desativa o botão durante o loading
                        style={{ 
                            backgroundColor: loading ? '#68C9A9' : '' // Define a cor de fundo "secondary" durante o loading
                        }}                    
                    >
                        {loading ? (
                            <svg width="20" height="20" viewBox="0 0 300 150"><path fill="none" stroke="#FFFFFF" stroke-width="30" stroke-linecap="round" stroke-dasharray="300 385" stroke-dashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"><animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate></path></svg>
                        ) : "Login"}
                    </BtnSecondary>
                        </div>
                        {localMsg && <div className="error_message">{localMsg}</div>}
                    </form>
                    <div className='criar_conta'>
                        <p>Não possui uma conta? <Link to='/criar-conta'>Cadastre-se</Link></p>
                    </div>
                </div>
            </Content>
        </Container>
    );
}

export default Login;
