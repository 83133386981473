import React, { useState, useContext, useRef } from 'react';
import { Container, Content, EnviarNovidade } from './style';
import { AiFillCloseCircle, AiOutlineSend } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { productContext } from '../../contexts/productsContext';
import { Context } from '../../contexts/authContext';
import { api } from '../../hooks/useApi';

const Painel = () => {
    const [newsOpen, setNewsOpen] = useState(false);
    const [newsValues, setNewsValues] = useState({});


    const handleNewsOpen = () => {
        setNewsOpen(prev => !prev);
    };


    const handleChangeNewsValues = (value) => {
        setNewsValues(prev => ({
            ...prev,
            [value.target.name]: value.target.value,
        }));
    };

    const sendNewsToUsers = async (e) => {
        e.preventDefault();
        try {
            await api.post('/enviar-novidades', {
                mensagem: newsValues.mensagem,
                link: newsValues.link
            });
            alert("Mensagem enviada com sucesso!");
        } catch (error) {
            alert(error.response?.data?.msg || "Erro ao enviar mensagem!");
        }
    };

    return (
        <Container>
            <EnviarNovidade>
                <div className={`overlay ${newsOpen && 'open_modal'}`} onClick={handleNewsOpen}></div>
                <div className={`modal_wrapper ${newsOpen && 'open_modal'}`}>
                    <h3>Enviar mensagem para os clientes</h3>
                    <div className='btn_close'>
                        <AiFillCloseCircle onClick={handleNewsOpen} />
                    </div>
                    <div className='form_modal'>
                        <form>
                            <textarea placeholder='Insira a mensagem para os clientes' name="mensagem" onChange={handleChangeNewsValues}></textarea>
                            <input type="text" name="link" placeholder="Insira um link para o produto ( Opcional )" onChange={handleChangeNewsValues} />
                            <button className='insert_produto' onClick={sendNewsToUsers}>Enviar</button>
                        </form>
                    </div>
                </div>
            </EnviarNovidade>

            <div className='nav_painel_title'>
                <p><Link to='/'>Ínicio</Link> / <Link to='/Perfil'>Minha Conta</Link> / Painel</p>
                <h3>Painel de controle</h3>
            </div>

            <Content>
                <div className="ordenar_produto">
                    <button className='enviar_novidade' onClick={handleNewsOpen}><AiOutlineSend /> Enviar novidades</button>
                </div>
            </Content>
        </Container>
    );
};

export default Painel;
